import { TimePicker, FormControl } from '@otto-finance/ui';
import { useField } from 'formik';

interface FormTimePickerFieldProps {
  label: string;
  name: string;
  caption?: JSX.Element | string;
  format?: '12' | '24';
  placeholder?: string;
  required?: boolean;
  step?: number;
  size?: 'mini' | 'compact' | 'default' | 'large';
  maxDate?: Date;
  minDate?: Date;
}

export const FormTimePickerField = ({
  label,
  name,
  caption,
  ...props
}: FormTimePickerFieldProps & { formatValue?: boolean }) => {
  const [field, _, helper] = useField({ name });
  return (
    <FormControl caption={caption} label={`${label}${props.required ? '*' : ''}`}>
      <TimePicker
        {...props}
        value={field.value}
        onChange={(date) => {
          if (!date) {
            helper.setValue(null);
          } else {
            helper.setValue(date);
          }
        }}
      />
    </FormControl>
  );
};
