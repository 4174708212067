import { ArrowLeftIcon, Button, useTheme } from '@otto-finance/ui';
import Link from 'next/link';

export const GoBack = ({ route, label }: { route: string; label?: string }) => {
  const [_css, theme] = useTheme();
  return (
    <Link href={route} prefetch={false}>
      {label ? (
        <Button
          shape="default"
          kind="tertiary"
          size="compact"
          startEnhancer={<ArrowLeftIcon color={theme.colors.accent500} />}
        >
          {label}
        </Button>
      ) : (
        <Button shape="circle" kind="tertiary" size="compact">
          <ArrowLeftIcon color={theme.colors.accent500} />
        </Button>
      )}
    </Link>
  );
};
