import { useLoginMutation } from 'api/auth.api';
import { useToast } from 'common/otto-ui/toast';
import { ROUTE_ENUM } from 'configuration/data/routers';
import { useAppDispatch } from 'configuration/redux/store';
import { UserChannelEnum, UserTypeEnum } from 'enums';
import { AuthSplitLayout } from 'layouts';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { updateCalendarConnectedStatus } from 'slices/calendar';
import { uiRouteSelector } from 'slices/ui';
import { captureException, hasSubscriptionExpired } from 'utils';
import { LoginFormPartial } from 'views/login/partials';

const NON_REDIRECT_ROUTES = [
  ROUTE_ENUM.LOGIN,
  ROUTE_ENUM.REGISTER,
  ROUTE_ENUM.FORGOT_PASS,
  ROUTE_ENUM.ACCOUNT_ACTIVATE,
  ROUTE_ENUM.RESTORE_PASS,
  ROUTE_ENUM.NOT_FOUND,
];

export const LoginView = () => {
  const router = useRouter();
  const { toast } = useToast();
  const [login, { isLoading, error }] = useLoginMutation();
  const route = useSelector(uiRouteSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    /**
     * Check for any router params on page load
     */
    if (router.query.account_deleted === 'true') {
      toast({
        title: 'Account Deleted',
        message: 'Your account was successfully deleted.',
        variant: 'success',
      });
      captureException('Account Deleted');
    }
  }, [router]);

  const onSubmit = async (values: { email: string; password: string }) => {
    await login(values)
      .unwrap()
      .then(async ({ user, calendarConnected }) => {
        let redirectTo: string = ROUTE_ENUM.HOME;
        if (user.type === UserTypeEnum.CUSTOMER) {
          if (user.channel === UserChannelEnum.BENEFITS && hasSubscriptionExpired(user.benefit)) {
            redirectTo = ROUTE_ENUM.SUB_EXPIRED;
          }
        } else if (user.type === UserTypeEnum.COACH) {
          dispatch(updateCalendarConnectedStatus(calendarConnected));
        }
        const deepLink = router.query?.link as string;
        if (deepLink) {
          try {
            redirectTo = new URL(deepLink, window.location.origin).toString();
          } catch (err) {
            captureException(err);
          }
        }
        if (route.prev && canRedirectToRoute(route.prev)) {
          redirectTo = route.prev;
        }
        await router.replace(redirectTo);
      });
  };

  return (
    <AuthSplitLayout title="Login" image="static/images/bg-leaf.webp">
      <LoginFormPartial onSubmit={onSubmit} isLoading={isLoading} errorMessage={error?.message} />
    </AuthSplitLayout>
  );
};

const canRedirectToRoute = (route: string) => !NON_REDIRECT_ROUTES.some((r) => route.startsWith(r));
