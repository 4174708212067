import { styled, useTheme } from '@otto-finance/ui';
import { Image } from 'components';

const avatarSizeMap = {
  xs: 24,
  sm: 32,
  md: 40,
  lg: 64,
  xl: 74,
  xxl: 94,
  xxl2: 120,
};

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxl2';

type AvatarProps = {
  src?: string;
  size?: AvatarSize;
  color?: string;
  fallback?: JSX.Element;
  padding?: string;
  fill?: boolean;
  title?: string;
};

export const FallbackIcon = styled<'div', { color: string }>('div', ({ $theme, color }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  display: 'flex',
  textAlign: 'center',
  fontWeight: 600,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  color: $theme.colors.white,
  backgroundColor: color,
}));

export const Avatar = ({
  src,
  size = 'md',
  color = null,
  fallback = null,
  padding = null,
  fill = false,
  title = '',
}: AvatarProps): JSX.Element => {
  const [css, theme] = useTheme();
  return (
    <div
      className={css({
        borderRadius: '50%',
        position: 'relative',
        width: `${avatarSizeMap[size]}px`,
        height: `${avatarSizeMap[size]}px`,
        padding: padding ? padding : theme.sizing.scale300,
        backgroundColor: color || theme.colors.grayScale100,
        overflow: 'hidden',
      })}
      title={title}
    >
      {src ? (
        <Image
          src={src}
          alt={title}
          {...(fill
            ? {
                fill: true,
                style: {
                  objectFit: 'cover',
                },
              }
            : {
                width: avatarSizeMap[size],
                height: avatarSizeMap[size],
              })}
          fallbackComponent={fallback}
        />
      ) : fallback ? (
        <div
          className={css({
            display: 'grid',
            justifyItems: 'center',
            alignItems: 'center',
            height: '100%',
            position: 'relative',
          })}
        >
          {fallback}
        </div>
      ) : null}
    </div>
  );
};
