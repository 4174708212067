'use client';

import * as PopoverPrimitive from '@radix-ui/react-popover';
import { forwardRef } from 'react';
import { Layer } from '@otto-finance/ui';
import clsx from 'clsx';

import styles from './popover.module.scss';

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverContent = forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ children, className, ...props }, forwardedRef) => (
  <Layer>
    <PopoverPrimitive.Content sideOffset={5} {...props} ref={forwardedRef} className={clsx(styles.Content, className)}>
      {children}
      {/* <PopoverPrimitive.Arrow className={styles.Arrow} /> */}
    </PopoverPrimitive.Content>
  </Layer>
));

export const PopoverClose = forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(
  ({ children }, forwardedRef) => <PopoverPrimitive.Close>{children}</PopoverPrimitive.Close>,
);
