const ALL_ROLES = ['coach', 'admin', 'customer'];
const COACH_ADMIN = ['coach', 'admin'];
const ADMIN = ['admin'];
const CUSTOMER = ['customer'];
const CUSTOMER_GUEST = ['customer', 'guest'];

const COACH_ONLY = {
  create: ['coach'],
  read: ['coach'],
  update: ['coach'],
  delete: ['coach'],
};

const acls = {
  users: {
    create: ADMIN,
    read: COACH_ADMIN,
    update: ADMIN,
    delete: ADMIN,
  },
  home: {
    create: ALL_ROLES,
    read: ALL_ROLES,
    update: ALL_ROLES,
    delete: ALL_ROLES,
  },
  coach: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  roadmap: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  wealth: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  investments: {
    read: CUSTOMER,
  },
  transactions: {
    read: CUSTOMER,
  },
  investment_analysis: {
    read: CUSTOMER,
  },
  assets: {
    read: CUSTOMER,
  },
  sessions: {
    create: COACH_ADMIN,
    read: COACH_ADMIN,
    update: COACH_ADMIN,
    delete: COACH_ADMIN,
  },
  messages: {
    create: COACH_ADMIN,
    read: COACH_ADMIN,
    update: COACH_ADMIN,
    delete: COACH_ADMIN,
  },
  coaches: {
    create: ADMIN,
    read: ADMIN,
    update: ADMIN,
    delete: ADMIN,
  },
  admins: {
    create: ADMIN,
    read: ADMIN,
    update: ADMIN,
    delete: ADMIN,
  },
  actions: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  calendar: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  events: {
    read: CUSTOMER,
  },
  learn: {
    read: CUSTOMER,
  },
  feedback: {
    create: ALL_ROLES,
    read: COACH_ADMIN,
    update: COACH_ADMIN,
    delete: COACH_ADMIN,
  },
  session: {
    create: ALL_ROLES,
    read: COACH_ADMIN,
    update: ALL_ROLES,
    delete: ALL_ROLES,
  },
  session_room: {
    read: ['customer', 'coach', 'guest'],
  },
  connect_calendar: COACH_ONLY,
  welcome: {
    read: CUSTOMER,
  },
  provide_email: {
    create: ADMIN,
    read: ADMIN,
    update: ADMIN,
    delete: ADMIN,
  },
  open_banking_auth: {
    read: CUSTOMER,
  },
  availability: COACH_ONLY,
  ottomate: {
    create: CUSTOMER,
    read: CUSTOMER,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  dashboard: {
    create: ALL_ROLES,
    read: ALL_ROLES,
    update: ALL_ROLES,
    delete: ALL_ROLES,
  },
  settings: {
    read: ALL_ROLES,
  },
  profile: {
    read: ALL_ROLES,
  },
  documents: {
    create: ALL_ROLES,
    read: ALL_ROLES,
    update: ALL_ROLES,
    delete: ALL_ROLES,
  },
  copilot: COACH_ONLY,
  reports: {
    create: COACH_ADMIN,
    read: COACH_ADMIN,
    update: COACH_ADMIN,
    delete: COACH_ADMIN,
  },
  report: {
    create: COACH_ADMIN,
    read: COACH_ADMIN,
    update: COACH_ADMIN,
    delete: COACH_ADMIN,
  },
  confirm_session: {
    create: CUSTOMER,
    read: CUSTOMER_GUEST,
    update: CUSTOMER,
    delete: CUSTOMER,
  },
  audio_recording: {
    read: ADMIN,
  },
};

export default acls;
